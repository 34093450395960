/**
 * Service Template
 * @Note - This template is only used to store data. A separate template is used
 *         for presentation. This is so that we can allow for real-time previews
 *         in a separate environment for content editors.
 */

import * as React from 'react';
import { graphql } from 'gatsby';

// Require the presentation component here, this is shared between the SSR template
// and the runtime preview template
import Page from '../presentation/service';

const ServiceTemplate = ({ data, pageContext, path }) => {
  return (
    <Page data={{ ...data.service }} slug={pageContext.slug} path={path} />
  );
};

export default ServiceTemplate;

export const query = graphql`
  query($id: String) {
    service: wpService(id: { eq: $id }) {
      id
      content
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
      }
      serviceAcf {
        pageIntroduction
        type
        heroUnit {
          heroImage {
            sourceUrl
          }
        }
        keyContacts {
          ... on WpStaffMember {
            id
            title
            slug
            staffCard {
              cardSummaryText
              hideProfileLink
              keyServices {
                keyService
              }
            }
            staffAcf {
              profileImage {
                sourceUrl
              }
            }
          }
        }
      }
      wpParent {
        node {
          ... on WpService {
            id
            title
            slug
            serviceAcf {
              heroUnit {
                heroImage {
                  sourceUrl
                }
              }
            }
            wpChildren {
              nodes {
                ... on WpService {
                  id
                  title
                  slug
                  wpParent {
                    node {
                      id
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
      wpChildren {
        nodes {
          ... on WpService {
            id
            title
            slug
            serviceAcf {
              type
            }
            wpParent {
              node {
                id
                slug
              }
            }
          }
        }
      }
    }
  }
`;
